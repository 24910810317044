.admin-nav ul {
    list-style-type: none;
}

.admin-nav ul li a {
    text-decoration: none !important;
    color: whitesmoke !important;
    font-weight: 700;
    letter-spacing: 1px;
}

.admin-nav ul li a:hover {
    color: coral !important;
}