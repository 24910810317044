/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

.bg-orange {
  background-color: #ff8965;
}

.header {
  color: #ff7155;
  text-transform: uppercase;
}

.sub-header {
  color: #ff7155;
}

.info div span {
  line-height: 2rem;
}

img {
  max-width: 100%;
  max-height: 100%;
}

.product:nth-child(even) {
  box-shadow: -7px 14px 17px -1px rgba(255, 137, 101, 0.69) !important;
  -webkit-box-shadow: -7px 14px 17px -1px rgba(255, 137, 101, 0.69) !important;
  -moz-box-shadow: -7px 14px 17px -1px rgba(255, 137, 101, 0.69) !important;
}

.product:nth-child(odd) {
  box-shadow: 7px 14px 17px -1px rgba(255, 137, 101, 0.69) !important;
  -webkit-box-shadow: 7px 14px 17px -1px rgba(255, 137, 101, 0.69) !important;
  -moz-box-shadow: 7px 14px 17px -1px rgba(255, 137, 101, 0.69) !important;
}

.products .product {
  background-color: #232222;
}

.products {
  display: flex;
  justify-content: space-between;
}

.categ {
  color: #fff;
  opacity: 1;
  font-weight: 500;
}

.details {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
}

.ship-fee {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 5rem;
}

.ending {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.ord-scr-cont {
  margin-top: 80px;
}

.quant {
  border: #fff 2px solid !important;
}

@media (max-width: 425px) {
  .ship-fee {
    min-height: 5rem;
  }

  .ship-fee span {
    font-size: 0.75rem !important;
  }

  .ship-fee span:first-child {
    font-size: 0.9rem;
    letter-spacing: 2px;
  }
}

@media (max-width: 768px) {
  .product:nth-child(even) {
    margin-left: auto;
  }

  .products {
    padding: 0 1.5rem;
  }

  .p-img {
    position: absolute;
    top: -0.85rem;
  }

  .info div {
    font-size: 0.9rem;
  }

  .details,
  .total-container,
  .check-btn,
  .ship-fee,
  .ending {
    font-size: 0.75rem;
  }

  .ending {
    padding: 0 1.5rem;
    line-height: 1.2rem;
  }

  .p-img-container {
    position: relative;
    margin-right: 1rem;
  }

  .product {
    position: relative;
    width: 90%;
    min-height: 8rem;
  }

  .quant {
    padding: 0.2rem;
    width: 70%;
    margin-bottom: 0.5rem;
  }

  .price {
    width: 30%;
  }

  .quant input {
    width: 2rem;
    height: 1rem;
  }

  .check-btn {
    color: #fff;
  }

  .check-btn:hover {
    background-color: #fff;
    color: #000;
  }
}

@media (min-width: 768px) and (max-width: 992px) {
  .p-img {
    position: absolute;
    top: -0.85rem;
  }

  .details {
    font-size: smaller;
  }

  .p-img-container {
    position: relative;
    margin-right: 1rem;
  }

  .product {
    position: relative;
    width: 45%;
    min-height: 8rem;
  }

  .quant {
    padding: 0.2rem;
    width: 70%;
    margin-bottom: 0.5rem;
  }

  .price {
    width: 30%;
  }

  .quant input {
    width: 2rem;
    height: 1rem;
  }

  .check-btn {
    color: #fff;
  }

  .check-btn:hover {
    background-color: #fff;
    color: #000;
  }

  .check-btn {
    width: 35%;
  }
}

@media (min-width: 992px) and (max-width: 1200px) {
  .p-img {
    position: absolute;
    top: -0.85rem;
  }

  .p-img-container {
    position: relative;
    margin-right: 1rem;
  }

  .product {
    position: relative;
    width: 45%;
    min-height: 8rem;
  }

  .quant {
    padding: 0.5rem;
    width: 60%;
  }

  .quant input {
    width: 1.7rem;
    margin-left: 1rem;
    height: 1rem;
  }

  .check-btn {
    color: #fff;
  }

  .check-btn:hover {
    background-color: #fff;
    color: #000;
  }

  .check-btn {
    width: 30%;
  }
}

@media (min-width: 1200px) {
  .p-img {
    position: absolute;
    top: -1.2rem;
  }

  .p-img-container {
    position: relative;
  }

  .product {
    position: relative;
    width: 45%;
    min-height: 10rem;
  }

  .categ {
    color: black;
    opacity: 1;
    font-weight: 500;
  }

  .details {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
  }

  .quant {
    padding: 1rem;
    width: 55%;
  }

  .quant input {
    width: 1.7rem;
    margin-left: 1rem;
    height: 1rem;
  }

  .check-btn {
    color: #fff;
  }

  .check-btn:hover {
    background-color: #fff;
    color: #000;
  }

  .check-btn {
    width: 25%;
  }
}
