@media (max-width: 768px) {
  .form-container3 {
    margin: 7rem 4rem 0;
    padding: 2rem 0rem;
  }
}

@media (min-width: 768px) and (max-width: 1200px) {
  .form-container3 {
    margin: 7rem 4rem 0;
    padding: 2rem 3rem;
  }
}

@media (min-width: 1200px) {
  .form-container3 {
    padding: 10rem 20rem;
  }
}

:root {
  --main-color: #ff8965;
}

.form-container3 {
    border-radius: 2rem;
    background-repeat: no-repeat;
    background-size: cover;
}

.ship-btns {
  color: #fff;
  font-size: larger;
  background-color: var(--main-color) !important;
  padding: 0.5rem 3rem;
}

.ship-btns:hover {
  opacity: 0.9;
  color: #fff;
}