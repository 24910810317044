:root {
  --main-color: #ff8965;
}

.link {
  text-decoration: none;
  color: inherit;
  opacity: 0.9;
  font-weight: bolder;
}

.link:hover {
  cursor: pointer;
  opacity: 0.7;
  color: inherit;
}

.thanks-cont {
  min-height: 89vh;
  text-align: center;
  padding: 3rem 0;
}

.thanks h1,
.also-like h2 {
  color: var(--main-color);
  text-transform: uppercase;
}

.also-like {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.like-products {
  display: flex;
  justify-content: space-evenly;
  margin-top: 3rem;
}

@media (max-width: 425px) {
  .like-products {
    width: 90%;
  }
}

@media (min-width: 425px) and (max-width: 768px) {
  .like-products {
    width: 60%;
  }
}

@media (min-width: 768px) {
  .like-products {
    width: 40%;
  }
}

.like-product img {
  width: 100%;
  border-radius: 2rem;
}

.like-product {
  position: relative;
}
.info {
  width: 100%;
  height: 100%;
  border-radius: 2rem;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
  background: rgba(0, 0, 0, 0.5);
  display: none;
}
.info {
  padding: 45% 0;
}
.info:hover {
  cursor: pointer;
  opacity: 0.7;
}
.like-product .image {
  text-align: center;
  position: relative;
  top: 50%;
}
.like-product:hover .info {
  display: block;
}
