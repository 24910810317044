@media (min-width: 425px) and (max-width: 768px) {
  .warning {
    padding: 1rem;
    height: 4rem;
    opacity: 0.5;
    position: absolute;
    z-index: 1000;
    text-align: center;
    width: 100%;
  }

  .warning p {
    font-size: 1rem;
  }
}

@media (min-width: 1900px) {
  .main-banner {
    height: max-content !important;
    width: 100vw !important;
  }
  .main-banner img {
    width: 100vw !important;
  }
}

@media (min-width: 768px) {
  .warning {
    opacity: 0.5;
    position: absolute;
    z-index: 1000;
    text-align: center;
    width: 100%;
    padding-top: 1rem;
  }

  .warning p {
    font-size: 1.2rem;
    line-height: 1.3rem;
  }
}

/* @media (min-width: 768px) and (max-width: 992px) {
  .main-banner {
    height: auto;
  }
}

@media (min-width: 576px) and (max-width: 768px) {
  .main-banner {
    height: 40vh;
  }
}

@media (min-width: 425px) and (max-width: 576px) {
  .main-banner {
    height: 37vh;
  }
} */
.main-banner {
  height: auto;
  max-height: 100vh !important;
}
.main-banner img {
  object-fit: contain;
}
@media (max-width: 425px) {
  /* .main-banner {
    height: 25vh;
  } */

  .warning {
    padding: 0.2rem;
    height: 1.5rem;
    opacity: 0.45;
  }

  .warning p {
    font-size: 0.5rem;
  }
}

@media only screen and (max-device-width: 480px) and (-webkit-min-device-pixel-ratio: 2) {
  .warningIos {
    padding: 3px;
    height: 33px;
    opacity: 0.6;
  }

  .warningIos p {
    font-size: 10px;
    text-align: center;
    line-height: 12px;
  }
}
