@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

* {
  font-family: "Poppins", sans-serif;
}

.-container {
  position: relative;
}

.-container .-card {
  position: relative;
  width: 320px;
  height: 350px;
  background: #232323;
  border-radius: 20px;
  overflow: hidden;
}

.priceProduct{
  background-color: #232323b7; 
  border-radius: 10px;
  padding: 2%;
}
/*
.-container .-card:before{
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #ff7155;
  clip-path: circle(150px at 80% 20%);
  transition: 0.5s ease-in-out;
}

.-container .-card:hover:before{
  clip-path: circle(300px at 80% -20%);
}

.-container .-card:after{
  content: 'NOVOBAR';
  position: absolute;
  top: 50%;
  left: -15%;
  transform: rotate(45deg);
  font-size: 5rem;
  font-weight: 800;
  font-style: italic;
  color: rgba(255,255,25,0.05)
}

.-container .-card .imgBx{
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  z-index: 10000;
  width: 100%;
  height: 220px;
  transition: 0.5s;
}

.-container .-card:hover .imgBx{
  top: 0%;
  transform: translateY(0%);
    
}

.-container .-card .imgBx img{
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) rotate(20deg);
  width: 7rem;
}
*/
.-container .-card .contentBx {
  bottom: 0;
  width: 100%;
  height: 100px;
  text-align: center;
  transition: 1s;
}

.-container .-card:hover .contentBx {
  height: 210px;
}

.-container .-card h2 {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 700;
  height: 3rem;
  font-size: 1.2rem;
  text-transform: uppercase;
  letter-spacing: 1px;
  color: #ff7155;
  text-shadow: 1px 1px 2px black;
  
}
.-card h2:hover{
color: #ff8400;
text-decoration: underline !important;

}
.-container .-card .contentBx .quantity,
.-container .-card .contentBx .price {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 8px 20px;
  transition: 0.5s;
  opacity: 0;
  visibility: hidden;
  padding-top: 0;
  padding-bottom: 0;
}

.-container .-card:hover .contentBx .quantity {
  opacity: 1;
  visibility: visible;
  transition-delay: 0.5s;
}

.-container .-card:hover .contentBx .price {
  opacity: 1;
  visibility: visible;
  transition-delay: 0.6s;
}

.-container .-card .contentBx .quantity h3,
.-container .-card .contentBx .price h3 {
  color: #fff;
  font-weight: 300;
  font-size: 14px;
  text-transform: uppercase;
  letter-spacing: 2px;
  margin-right: 10px;
}

.-container .-card .contentBx .quantity span {
  width: 26px;
  height: 26px;
  text-align: center;
  line-height: 26px;
  font-size: 14px;
  display: inline-block;
  color: #111;
  background: #fff;
  margin: 0 5px;
  transition: 0.5s;
  color: #111;
  border-radius: 4px;
  cursor: pointer;
}

.-container .-card .contentBx .quantity span:hover {
  background: #9bdc28;
}

.-container .-card .contentBx .butn {
  display: inline-block;
  padding: 10px 20px;
  background: #fff;
  border-radius: 4px;
  margin-top: 10px;
  text-decoration: none;
  font-weight: 600;
  color: #111;
  opacity: 0;
  transform: translateY(50px);
  transition: 0.5s;
  margin-top: 0;
}

.-container .-card:hover .contentBx .butn {
  opacity: 1;
  transform: translateY(0px);
  transition-delay: 0.75s;
}

.contentBx {
  left: 0;
}

.btn-clear {
  background-color: #ff7155;
  color: #fff;
  padding: 1rem 2rem;
  border-radius: 0.5rem;
}

.link-clear {
  color: #111;
  text-decoration: none;
}

.link-clear:hover {
  opacity: 0.75;
  color: #111;
}

.-card {
  width: var(--card-width);
  height: var(--card-height);
  position: relative;
  display: flex;
  justify-content: center;
  align-items: flex-end;
  padding: 0 36px;
  perspective: 2500px;
  margin: 0 50px;
}

.cover-image {
  width: 100%;
  height: 100%;
  object-fit: fill;
}

.wrapper {
  transition: all 0.5s;
  position: absolute;
  width: 100%;
  z-index: -1;
}

.-card:hover .wrapper {
  display: none;
}

.wrapper::before,
.wrapper::after {
  content: "";
  opacity: 0;
  width: 100%;
  height: 80px;
  transition: all 0.5s;
  position: absolute;
  left: 0;
}
.wrapper::before {
  top: 0;
  height: 100%;
  background-image: linear-gradient(
    to top,
    transparent 46%,
    rgba(12, 13, 19, 0.5) 68%,
    rgba(12, 13, 19) 97%
  );
}
.wrapper::after {
  bottom: 0;
  opacity: 1;
  background-image: linear-gradient(
    to bottom,
    transparent 46%,
    rgba(12, 13, 19, 0.5) 68%,
    rgba(12, 13, 19) 97%
  );
}

.-card:hover .wrapper::before,
.wrapper::after {
  opacity: 1;
}

.-card:hover .wrapper::after {
  height: 120px;
}

.contentBx {
  position: relative;
}

.fixTitle h2 {
  width: 320px;
  z-index: 500 !important
}

.fixTitle {
  position: absolute;
  bottom: 1rem;
  left: 0rem;
  text-align: center;
  text-decoration: none !important;
}

.title {
  width: 100%;
  height: 2rem;
  transition: all 0.1s;
  opacity: 0;
}
.-card:hover .title {
  opacity: 1;
  transform: translate3d(0%, 1.5rem, 100px);
}

.character {
  width: 45%;
  object-fit: scale-down;
  opacity: 0;
  transition: all 0.5s;
  position: absolute;
  z-index: -1000 !important;
}

.-card:hover .character {
  opacity: 0.9;
  transform: translate3d(0%, -25%, 100px);
}

.-card .fruit {
  position: absolute;
  width: max-content;
  bottom: -2rem;
  z-index: -2;
  object-fit: contain;
  opacity: 0;
  transition: all 1s ease;
}
.-card:hover .fruit {
  opacity: 1;
  transform: translate3d(0%, -20%, 100px);
}
