.c2-img {
  object-fit: contain;
}
.allinOne{
  filter: saturate(0);
  transition: 1s;
}

.allinOne:hover{
  filter: saturate(1.5);
  transition: 1s;
}
@media (max-width: 1024px) {
  .c2-item {
    height: auto;
  }
}

@media (min-width: 1024px) and (max-width: 1200px) {
  .c2-item {
    max-height: fit-content;
  }

  .c2-img {
    object-fit: scale-down;
  }
}

@media (min-width: 1200px) {
    .c2-item {
        height: 80vh;
    }
}
