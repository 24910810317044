:root {
  --main-color: #ff8965;
}
main {
  background-color: #111;
}

.col-orng {
  color: var(--main-color);
}

.butn {
  border-radius: 0.5rem;
  padding: 2% 4%;
}

@media (max-width: 768px) {
  .buy-btn {
    width: 100%;
  }

  .quant {
    width: 50%;
  }
}

@media (min-width: 768px) {
  .buy-btn {
    width: 50%;
  }

  .quant {
    width: 25%;
  }
}

@media (max-width: 425px) {
  .product-sides {
    width: 90%;
    display: flex;
    justify-content: space-evenly;
    margin-bottom: 2rem;
  }
}

@media (min-width: 425px) and (max-width: 768px) {
  .product-sides {
    width: 50%;
    display: flex;
    justify-content: space-evenly;
    margin-bottom: 2rem;
  }
}

@media (min-width: 768px) {
  .product-sides {
    width: 50%;
    display: flex;
    justify-content: space-evenly;
    margin-bottom: 2rem;
  }
}

@media (min-width: 1200px) {
  .side-cont {
    width: 30%;
    height: 10rem;
  }
}

@media (min-width: 992px) and (max-width: 1200px) {
  .side-cont {
    width: 30%;
    height: 7rem;
  }
}

@media (max-width: 992px) and (min-width: 768px) {
  .side-cont {
    width: 30%;
    height: 6.5rem;
  }
}

@media (min-width: 425px) and (max-width: 768px) {
  .side-cont {
    width: 30%;
    height: 5rem;
  }
}

@media (max-width: 425px) {
  .side-cont {
    width: 40%;
    height: 4rem;
  }
}

.side-cont .side-img {
  width: 100%;
  height: 100%;
  object-fit: contain;
  padding: 5%;
}

.side-cont .side-img:hover {
  cursor: pointer;
  opacity: 0.75;
  border: #fff 1px solid;
}

@media (min-width: 768px) {
  .magn-cont {
    height: 23rem;
    max-width: 23rem;
  }
}

@media (min-width: 425px) and (max-width: 768px) {
  .magn-cont {
    height: 20rem;
    max-width: 23rem;
  }
}

@media (max-width: 425px) {
  .magn-cont {
    height: 20rem;
    max-width: 23rem;
  }
}



/* like part */

.also-like h2 {
  color: var(--main-color);
  text-transform: uppercase;
}

.also-like {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.like-products {
  display: flex;
  justify-content: space-evenly;
  margin-top: 3rem;
}

@media (max-width: 425px) {
  .like-products {
    width: 90%;
  }
}

@media (min-width: 425px) and (max-width: 768px) {
  .like-products {
    width: 60%;
  }
}

@media (min-width: 768px) {
  .like-products {
    width: 40%;
  }
}

.like-product img {
  width: 100%;
  border-radius: 2rem;
}

.like-product {
  position: relative;
}
.info {
  width: 100%;
  height: 100%;
  border-radius: 2rem;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
  background: rgba(0, 0, 0, 0.5);
  display: none;
}
.info {
  padding: 45% 0;
}
.info:hover {
  cursor: pointer;
  opacity: 0.7;
}
.like-product .image {
  text-align: center;
  position: relative;
  top: 50%;
}
.like-product:hover .info {
  display: block;
}

@media (max-width: 500px) {
  .magn-cont-ios {
  width: 150px !important;
  padding-bottom: 10px;
}

.magn-ios .magnifier-image {
  width: 150px !important;
}
}

@media (min-width: 764px) and (max-width: 1370px) {
  .magn-cont-tab {
    width: 200px !important;
  }
  .magn-tab .magnifier-image {
    width: 200px !important;
  }
}

/* .magn-cont-iphone {
  width: 150px;
}

.magn-cont-ipad {
  width: 200px;
}

.magn-iphone .magnifier-image {
  width: 150px !important;
}

.magn-ipad .magnifier-image {
  width: 200px !important;
  padding-bottom: 20px !important;
} */

/* ios magnifier part */

/* .image-container {
  position: relative;
  width: 100%;
  height: 100%;
}

.image-container .img-magnified {
  display: block;
  max-width: 100%;
}

.magnifier {
  position: absolute;
  width: 100px;
  height: 100px;
  border: 2px solid #ccc;
  border-radius: 50%;
  background-repeat: no-repeat;
  background-size: 400% 400%;
  z-index: 999;
  visibility: hidden;
  pointer-events: none;
  overflow: hidden;
} */

/* ios magnifier part */

/* .image-container-magn {
  position: relative;
  width: 100%;
  height: 100%;
}

.image-container-magn img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: transform 0.3s ease-in-out;
}

.image-container-magn:hover img {
  transform: scale(1.1);
} */
/* i hate apple */

/* @media only screen and (min-device-width: 768px) and (max-device-width: 1024px) {
  .iPad-img {
    width: 200px !important;
  }
} */

/* iPad 1, 2, Mini and Air */
/* @media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (-webkit-min-device-pixel-ratio: 1) {
  .iPad-img {
    width: 20px !important;
  }

  .ipad-img-cont {
    width: 20px !important;
  }
} */

/* iPad 3, 4 and Pro 9.7" */
/* @media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (-webkit-min-device-pixel-ratio: 2) {
  .iPad-img {
    width: 20px !important;
  }

  .ipad-img-cont {
    width: 20px !important;
  }
} */
