.history-header h1 {
  color: #ff8965;
}
.history-header h3 {
  color: #fff;
}
.color-orange {
  color: #ff8965;
}
.history-header h3 {
  font-weight: 500;
}

@media (max-width: 425px) {
  table {
  font-size: 0.5rem;
}
}

@media (min-width: 425px) and (max-width: 768px) {
  table {
  font-size: 0.69rem;
}
}