.flash {
  animation: flash 800ms infinite;
  font-size: 0.8rem;
  line-height: 1rem;
  color: rgb(255, 191, 52);
}

@keyframes flash {
  50% {
    opacity: 0.8;
  }
}

.sign-link {
  color: #ff8965;
}

.sign-link:hover {
  color: #ff8965b1;
}

@media (min-width: 425px) {
  #signup label {
    font-size: 0.7rem;
  }
}

@media (max-width: 576px) {
  #signup label {
    font-size: 0.5rem;
  }

  .haveAcc-link {
    font-size: 0.7rem;
  }
}

@media (max-width: 425px) {
    .form-container2 {
        margin: 7rem 0 0 !important;
    }
}

/* @media (max-width: 1200px) {
  .form-container {
    margin: 7rem 4rem 0;
    padding: 2rem 3rem;
  }
} */

@media (min-width: 1200px) {
  .form-container2 {
    margin: 0 4rem;
    padding: 10rem 20rem;
  }
}

@media only screen and (max-device-width: 480px) and (-webkit-min-device-pixel-ratio: 2) {
  .form-container2-ios {
        margin: 112px 0 0 !important;
    }

  .sign-in-btn-ios {
    width: 150px;
    font-size: 12px;
    background-color: #ff8965;
    color: #fff;
    border-radius: 10px;
    padding: 16px 0;
  }
}