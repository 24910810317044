@media (min-width: 576px) and (max-width: 768px) {
    .spec-img {
        height: 70vh;
        width: 100vw;
    }
}

@media (min-width: 768px) and (max-width: 1200px) {
    .spec-img {
        height: 80vh;
        width: 100vw;
    }
}

@media (min-width: 1200px) {
    .spec-cont {
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .spec-img {
        height: 100vh;
        width: 100vw;
    }
}
