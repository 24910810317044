.orders__page {
  background-color: black;
  min-height: 100vh;
  position: relative;
  display: flex;
  flex-direction: column;
}

.orders_table {
  padding: 4%;
  color: white !important;
}
.link {
  cursor: pointer;
  font-size: 18px;
  position: relative;
  white-space: nowrap;
  color: var(--color-text);
}

.link::before,
.link::after {
  position: absolute;
  width: 100%;
  height: 1px;
  background: currentColor;
  top: 100%;
  left: 0;
  pointer-events: none;
}

.link::before {
  content: "";
  /* show by default */
}

.link--metis {
  font-family: font-alethia-pro;
  margin-left: 3%;
}

.link--metis::before {
  transform-origin: 100% 50%;
  transform: scale3d(0, 1, 1);
  transition: transform 0.3s;
}

.link--metis:hover::before {
  transform-origin: 0% 50%;
  transform: scale3d(1, 1, 1);
}

.badge_status {
  width: 50%;
  float: left;
  text-align: right;
}

/* .stock-col {
    display: flex;
    justify-content: center;
}

.stock-col input {
    width: 30% !important;
    display: inline-block;
} */