.history {
  filter: invert(1) !important;
}
.history:hover {
  width: 25px !important;
}
.top-nav {
  position: fixed !important;
  width: 100% !important;
  z-index: 50000;
}

.navbar-nav li a {
  color: #fff;
  font-weight: 700;
}

.navbar-nav li a:hover,
.navbar-nav li a:active {
  color: coral;
}

@media (max-width: 992px) {
  /* .form-select {
    max-width: 12%;
  } */
  .bg-trans-black {
    background-color: #000;
    opacity: 0.75;
    padding: 0 1rem;
  }
  /* .search:focus {
    width: 200px;
  } */
  .-nav-menu {
    flex-wrap: wrap;
  }
  /* .search-div {
    margin: 1rem 0 1.5rem;
    width: 80%;
  }

  .search-div .search-container input {
    padding: 0.5rem 1rem;
  }

  .search-div .search-container label {
    display: none;
  }

  .search-div .search-container, .search-div .search-container input {
    width: 100%;
  } */

}

@media (min-width: 992px) and (max-width: 1200px) {
  /* .-nav-menu {
    width: 45%;
  } */
  .-nav-links {
    width: 55%;
    font-size: 0.8rem;
  }
  /* .search:focus {
    width: 100px;
    padding: 0 16px 0 0;
  } */
}

@media (min-width: 1200px) {
  /* .-nav-menu {
    width: 30%;
  } */
  .-nav-links {
    width: 90%;
  }
  /* .search:focus {
    width: 200px;
    padding: 0 16px 0 0;
  } */
}

@media (min-width: 992px) {
  /* expanding search bar */
  /* .button {
    display: inline-block;
    margin: 4px 2px;
    background-color: #444;
    font-size: 14px;
    padding-left: 32px;
    padding-right: 32px;
    height: 50px;
    line-height: 50px;
    text-align: center;
    color: white;
    text-decoration: none;
    cursor: pointer;
    -moz-user-select: none;
    -khtml-user-select: none;
    -webkit-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }

  .button:hover {
    transition-duration: 0.4s;
    -moz-transition-duration: 0.4s;
    -webkit-transition-duration: 0.4s;
    -o-transition-duration: 0.4s;
    background-color: white;
    color: black;
  }

  .search-container {
    position: relative;
    display: inline-block;
    margin: 4px 2px;
    height: 50px;
    width: 50px;
    vertical-align: bottom;
  }

  .mglass {
    display: inline-block;
    pointer-events: none;
    -webkit-transform: rotate(-45deg);
    -moz-transform: rotate(-45deg);
    -o-transform: rotate(-45deg);
    -ms-transform: rotate(-45deg);
  }

  .searchbutton {
    position: absolute;
    font-size: 22px;
    width: 100%;
    margin: 0;
    padding: 0;
  }

  .search:focus + .searchbutton {
    transition-duration: 0.4s;
    -moz-transition-duration: 0.4s;
    -webkit-transition-duration: 0.4s;
    -o-transition-duration: 0.4s;
    background-color: white;
    color: black;
  }

  .search {
    position: absolute;
    left: 49px; /* Button width-1px (Not 50px/100% because that will sometimes show a 1px line between the search box and button) 
    background-color: white;
    outline: none;
    border: none;
    padding: 0;
    width: 0;
    height: 100%;
    z-index: 10;
    transition-duration: 0.4s;
    -moz-transition-duration: 0.4s;
    -webkit-transition-duration: 0.4s;
    -o-transition-duration: 0.4s;
  }

  .expandright {
    left: auto;
    right: 49px;
  }

  .expandright:focus {
    padding: 0 0 0 16px;
  }

  .search-div {
    width: 40%;
    display: flex;
    justify-content: right;
  }*/
} 

/* .form-select {
  padding: 0 0.5rem;
  border: none;
} */

nav {
  height: 80px !important;
}

.usr-links {
  display: flex;
  justify-content: space-evenly;
  width: 60%;
}

.usr-menu {
  margin: 0 !important;
}

/* auto close navbar */
