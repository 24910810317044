.pay {
  box-sizing: border-box;
  background: #232222 !important;
  border-radius: 40px;
  display: flex;
  align-items: center;
}

.pay:hover {
  cursor: pointer;
}
.pay img {
  position: relative !important;
  left: 25%;
  object-fit: contain;
  padding: 2rem 0;
}
.payChecked img {
  position: relative !important;
  left: 25%;
  object-fit: contain;
  padding: 2rem 0;
}
.payText {
  font-family: "Helvetica";
  font-style: normal;
  font-size: 30px;
  line-height: 46px;
  text-align: center;
  color: #ff8965;
}
.btn-continue {
  background: #939393 !important;
  border-radius: 28px;
  padding: 1rem 2rem;
  width: 25%;
  margin-bottom: 2rem;
}
.btn-continue:hover {
  opacity: 0.9;
}
.btn-continue.active {
  background: #ff8965 !important;
  color: #fff;
  outline: none;
  border: none;
  padding: 1.5rem 2rem;
  font-size: 1.2rem;
}

.payChecked {
  background: #ff8965;
  border-radius: 40px;
  display: flex;
  align-items: center;
}

/* box shadows */

.payChecked:nth-child(even) {
  box-shadow: -7px 14px 17px -1px rgba(255, 137, 101, 0.69) !important;
  -webkit-box-shadow: -7px 14px 17px -1px rgba(255, 137, 101, 0.69) !important;
  -moz-box-shadow: -7px 14px 17px -1px rgba(255, 137, 101, 0.69) !important;
}

.payChecked:nth-child(odd) {
  box-shadow: 7px 14px 17px -1px rgba(255, 137, 101, 0.69) !important;
  -webkit-box-shadow: 7px 14px 17px -1px rgba(255, 137, 101, 0.69) !important;
  -moz-box-shadow: 7px 14px 17px -1px rgba(255, 137, 101, 0.69) !important;
}

.pay:nth-child(even) {
  box-shadow: -7px 14px 17px -1px rgba(250, 250, 250, 0.75) !important;
  -webkit-box-shadow: -7px 14px 17px -1px rgba(250, 250, 250, 0.75) !important;
  -moz-box-shadow: -7px 14px 17px -1px rgba(250, 250, 250, 0.75) !important;
}

.pay:nth-child(odd) {
  box-shadow: 7px 14px 17px -1px rgba(250, 250, 250, 0.75) !important;
  -webkit-box-shadow: 7px 14px 17px -1px rgba(250, 250, 250, 0.75) !important;
  -moz-box-shadow: 7px 14px 17px -1px rgba(250, 250, 250, 0.75) !important;
}

/* media querries */

@media (min-width: 768px) {
  .pay,
  .payChecked {
    width: 45% !important;
    margin: 5rem 0;
  }
  .meth-cont {
    display: flex;
    justify-content: space-evenly;
  }
  .pay:nth-child(odd), .payChecked:nth-child(odd) {
    margin-right: 5rem;
  }
}

@media (min-width: 576px) and (max-width: 768px) {
  .pay,
  .payChecked {
    width: 90%;
    height: 12rem;
    margin: 5rem 0;
  }
  .pay:nth-child(even) {
    margin-left: auto;
  }
  .payChecked:nth-child(even) {
    margin-left: auto;
  }
  .btn-continue {
    width: 50%;
    margin-bottom: 1rem;
  }
}

@media (max-width: 576px) {
  .pay,
  .payChecked {
    width: 80%;
    height: 10rem;
    margin: 5rem 0;
  }
  .pay:nth-child(even) {
    margin-left: auto;
    margin-right: 1rem;
  }
  .payChecked:nth-child(even) {
    margin-left: auto;
    margin-right: 1rem;
  }
  .pay:nth-child(odd) {
    margin-left: 1rem;
  }
  .payChecked:nth-child(odd) {
    margin-left: 1rem;
  }
  .btn-continue {
    width: 45%;
    margin-bottom: 1rem;
  }
}
