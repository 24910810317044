* {
	box-sizing: border-box;
	outline: none;
    border: none;
}
.album{
    padding: 1% 5%;
}


body {
	line-height: 1;
}
ol, ul {
	list-style: none;
}
blockquote, q {
	quotes: none;
}
blockquote:before, blockquote:after,
q:before, q:after {
	content: '';
	content: none;
}
table {
	border-collapse: collapse;
	border-spacing: 0;
}

ul, ol {
	list-style: none;
}

.text-fx{
  box-shadow: inset 0 0 0 0 #54b3d6;
  color: #c054d6;
  padding: 0 .25rem;
  margin: 0 -.25rem;
  transition: color .3s ease-in-out, box-shadow .3s ease-in-out;
}


.text-fx:hover {
  color: #fff;
  box-shadow: inset 200px 0 0 0 #8c098a;;
  border-radius: 10px;
}

.text-fx-2{
  box-shadow: inset 0 0 0 0 #54b3d6;
  color: #fff;
  padding: 0 .25rem;
  margin: 0 -.25rem;
  transition: color .3s ease-in-out, box-shadow .3s ease-in-out;
}


.text-fx-2:hover {
  color: #fff;
  box-shadow: inset 200px 0 0 0 #ff7155;;
  border-radius: 10px;
}

/* Presentational styles */
h3 {
  color: #54b3d6;
  font-family: 'Poppins', sans-serif;
  font-size: 27px;
  font-weight: 700;
  line-height: 1.5;
  text-decoration: none;
}
.categ{
  opacity: 0.5;
}
.categ:hover{
  opacity: 0.9;
  transition-duration: 1s;
}

.round-t {
    border-radius: 2rem 2rem 0 0;
}

.round-b {
    border-radius: 0 0 2rem 2rem;
}

.s-container {
  width: 100%;
}

.card-container {
  position: relative;
}

@media (min-width: 576px) and (max-width: 992px) {
  .card-container {
    width: 75%;
  }
}

@media (min-width: 992px) {
  .card-container {
    width: 50%;
  }
}

.s-over {
  position: absolute;
  z-index: 100;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
}

.cardTitle, .cardSubTitle {
  color: #fff;
}

.text-fx-2{
  box-shadow: inset 0 0 0 0 #54b3d6;
  color: #fff;
  padding: 0 .25rem;
  margin: 0 -.25rem;
  transition: color .3s ease-in-out, box-shadow .3s ease-in-out;
}


.text-fx-2:hover {
  color: #fff;
  box-shadow: inset 200px 0 0 0 #ff7155;;
  border-radius: 10px;
}

@media (min-width: 992px) {
  

ul li {
  margin: 0;
  list-style-type: none;
}
ul {
    padding: 0;
}
  .the-arrow {
  width: 1px;
  transition: all 0.2s;
}
.the-arrow.-left {
  position: absolute;
  top: 60%;
  left: 0;
}
.the-arrow.-left > .shaft {
  width: 0;
  background-color: #bdbdbd;
}
.the-arrow.-left > .shaft:before,
.the-arrow.-left > .shaft:after {
  width: 0;
  background-color: #bdbdbd;
}
.the-arrow.-left > .shaft:before {
  transform: rotate(0);
}
.the-arrow.-left > .shaft:after {
  transform: rotate(0);
}
.the-arrow.-right {
  top: 3px;
}
.the-arrow.-right > .shaft {
  width: 1px;
  transition-delay: 0.2s;
}
.the-arrow.-right > .shaft:before,
.the-arrow.-right > .shaft:after {
  width: 8px;
  transition-delay: 0.3s;
  transition: all 0.5s;
}
.the-arrow.-right > .shaft:before {
  transform: rotate(40deg);
}
.the-arrow.-right > .shaft:after {
  transform: rotate(-40deg);
}
.the-arrow > .shaft {
  background-color: #bdbdbd;
  display: block;
  height: 1px;
  position: relative;
  transition: all 0.2s;
  transition-delay: 0;
  will-change: transform;
}
.the-arrow > .shaft:before,
.the-arrow > .shaft:after {
  background-color: #fff;
  content: "";
  display: block;
  height: 1px;
  position: absolute;
  top: 0;
  right: 0;
  transition: all 0.2s;
  transition-delay: 0;
}
.the-arrow > .shaft:before {
  transform-origin: top right;
}
.the-arrow > .shaft:after {
  transform-origin: bottom right;
}
.animated-arrow {
  display: inline-block;
  color: #fff;
  font-size: 1.25em;
  font-style: italic;
  text-decoration: none;
  position: relative;
  transition: all 0.2s;
}
.animated-arrow:hover {
  color: #bdbdbd;
}
.animated-arrow:hover > .the-arrow.-left > .shaft {
  width: 64px;
  transition-delay: 0.1s;
  background-color: #bdbdbd;
}
.animated-arrow:hover > .the-arrow.-left > .shaft:before,
.animated-arrow:hover > .the-arrow.-left > .shaft:after {
  width: 8px;
  transition-delay: 0.1s;
  background-color: #bdbdbd;
}
.animated-arrow:hover > .the-arrow.-left > .shaft:before {
  transform: rotate(40deg);
}
.animated-arrow:hover > .the-arrow.-left > .shaft:after {
  transform: rotate(-40deg);
}
.animated-arrow:hover > .main1 {
  transform: translateX(17px);
  transform: translateX(80px);
}
.animated-arrow:hover > .main1 > .the-arrow.-right > .shaft {
  width: 0;
  transform: translateX(200%);
  transition-delay: 0;
}
.animated-arrow:hover > .main1 > .the-arrow.-right > .shaft:before,
.animated-arrow:hover > .main1 > .the-arrow.-right > .shaft:after {
  width: 0;
  transition-delay: 0;
  transition: all 0.1s;
}
.animated-arrow:hover > .main1 > .the-arrow.-right > .shaft:before {
  transform: rotate(0);
}
.animated-arrow:hover > .main1 > .the-arrow.-right > .shaft:after {
  transform: rotate(0);
}
.animated-arrow > .main1 {
  display: flex;
  align-items: center;
  transition: all 0.2s;
  padding: 1rem 2rem;
  background: none;
  border-radius: 1rem;
}
.animated-arrow > .main1 > .text {
  margin: 0 16px 0 0;
  line-height: 1;
}
.animated-arrow > .main1 > .the-arrow {
  position: relative;
}

}