@media (max-width: 425px) {
  .form-container {
    padding: 3rem 0 !important;
    margin: 7rem 0 0 !important;
  }
}

@media only screen and (max-device-width: 480px) and (-webkit-min-device-pixel-ratio: 2) {
  .form-container-ios {
    padding: 48px 0 !important;
    margin: 112px 0 0 !important;
  }

  .sign-in-btn-ios {
    width: 150px;
    font-size: 12px;
    background-color: #ff8965;
    color: #fff;
    border-radius: 10px;
    padding: 16px 0;
  }
}

/* @media (max-width: 1200px) {
  .form-container {
    margin: 7rem 4rem 0;
    padding: 2rem 3rem;
  }
} */

@media (min-width: 1200px) {
  .form-container {
    margin: 0;
    padding: 10rem 17rem 4rem;
  }
}

.divider {
  display: flex;
  justify-content: center;
  margin: 2rem 0;
}

.divider div {
  height: 1px;
  width: 75%;
  background-color: azure;
}

.sign-in-btn-container {
  display: flex;
  justify-content: center;
}

.sign-up-btn-container span a {
  color: #ff8965 !important;
}

.sign-up-btn-container span a:hover {
  cursor: pointer;
  opacity: 0.8;
}

/* .sign-up-link {
  width: 48%;
  color: #ff8965 !important;
} */
/* 
.sign-up-btn {
  width: 100%;
  background-color: #ff8965;
  padding: 1rem 1rem;
  color: white;
  border-radius: 1rem;
  height: 3.65rem;
} */

.sign-in-btn {
  background-color: #ff8965;
  color: #fff;
}

.sign-in-btn:hover {
  background-color: #ff8965bf;
}

.pointer:hover {
  cursor: pointer;
}

.sign-up-btn-container {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}

@media (max-width: 425px) {
  .sign-in-btn {
    width: 65%;
    font-size: 0.75rem;
    border-radius: 0.7rem;
    padding: 1rem 0;
  }
}

@media (min-width: 425px) {
  .sign-in-btn {
    width: 48%;
    padding: 1rem;
    border-radius: 1rem;
  }
}
