:root {
  --main-color: #ff8965;
}

@media (min-width: 992px) {
  .margin-top-lg {
    margin-top: 15%;
  }
}

@media (max-width: 992px) {
  .margin-top-lg {
    margin-top: 30%;
  }
}

#notAllowed:hover {
  background-color: var(--main-color) !important;
}

/* .cart-cont {
  height: 83vh;
} */

.cart-btns {
  color: #fff;
  background-color: var(--main-color) !important;
}

.cart-btns:hover {
  opacity: 0.9;
  color: #fff;
}
.articles {
  width: 100%;
  margin-top: 2rem;
}

.-cart-item {
  background-color: var(--main-color);
  margin-bottom: 2rem;
  position: relative;
}

/* .cart-product-img {
  border-radius: ;
} */

/* .-cart-item-name {
} */

@media (max-width: 425px) {
  .cart-btns {
    padding: 0.5rem 1.5rem;
    font-size: 0.6rem;
  }
  .cart-product-img {
    object-fit: scale-down;
    max-height: 3rem;
  }
  .cart-cont {
    font-size: 0.7rem;
  }
  .-cart-item {
    padding: 2% 5%;
    border-radius: 0.7rem;
  }
}

@media (min-width: 425px) and (max-width: 756px) {
  .cart-cont {
    font-size: 0.7rem;
  }
  .cart-product-img {
    object-fit: scale-down;
    max-height: 5rem;
  }
  .cart-product {
    padding: 1rem 0;
  }

  .cart-item-link {
    font-size: 1.2rem;
  }

  .-cart-item {
    padding: 4% 3%;
    border-radius: 1rem;
  }
}

@media (min-width: 756px) {
  .cart-product-img {
    object-fit: scale-down;
    max-height: 8rem;
  }
  .cart-product {
    padding: 1rem 0;
  }

  .cart-btns {
    padding: 0.5rem 3rem;
  }
  
  .cart-item-link {
    font-size: 1.5rem;
  }

  .-cart-item {
    padding: 4% 3%;
    border-radius: 1.3rem;
  }
}
